<!-- 库存管理 -->
<template>
  <div class="pagePadding homes">
    <div class="autoDiv">
      <Form inline class="formMarginBtm20">
        <FormItem>
          <span>发起仓库：</span>
          <Select class="iviewIptWidth250" v-model="queryFrom.from_warehouse_id" filterable clearable @on-change="changeWare">
            <Option v-for="item in storeList" :value="item.warehouse_id" :key="item.warehouse_id" :disabled="item.warehouse_id == queryFrom.to_warehouse_id">{{ item.warehouse_name }}</Option>
          </Select>
        </FormItem>
        <FormItem class="marginLeft40">
          <span>调拨仓库：</span>
          <Select class="iviewIptWidth250" v-model="queryFrom.to_warehouse_id" filterable clearable>
            <Option v-for="item in storeList" :value="item.warehouse_id" :key="item.warehouse_id" :disabled="item.warehouse_id == queryFrom.from_warehouse_id">{{ item.warehouse_name }}</Option>
          </Select>
        </FormItem>
        <!-- 出库时间 -->
        <FormItem class="marginLeft40">
          <span>调拨时间：</span>
          <DatePicker clearable placeholder="请选择" format="yyyy-MM-dd" :value="queryFrom.create_time_start" :editable="false" @on-change="changeTime($event, 1)" class="iviewIptWidth250"></DatePicker>
          <span style="padding: 0 10px;">至</span>
          <DatePicker clearable placeholder="请选择" format="yyyy-MM-dd" :value="queryFrom.create_time_end" :options="options" :editable="false" @on-change="changeTime($event, 2)" class="iviewIptWidth250"></DatePicker>
        </FormItem>
        <FormItem>
          <span>产品编号：</span>
          <i-input placeholder="请输入" class="iviewIptWidth250" v-model="queryFrom.product_code"></i-input>
        </FormItem>
        <FormItem class="marginLeft40">
          <span>产品名称：</span>
          <Select placeholder="请选择" v-model="queryFrom.product_name" clearable filterable class="iviewIptWidth250" @on-change="productChange">
            <Option v-for="(item, index) in productList" :value="item.product_name" :key="index">{{ item.product_name }}</Option>
          </Select>
        </FormItem>
        <FormItem class="marginLeft40">
          <span>规格型号/SKU：</span>
          <Select placeholder="请选择" v-model="specification_Arr" clearable class="iviewIptWidth250" multiple filterable :max-tag-count="specification_Arr.length == 1 ? 1 : 0">
            <Option v-for="item in modelList" :value="item.model_name ? item.model_name : item.item_number" :key="item.specification_id" :label="(item.model_name ? item.model_name : '') + '-' + (item.item_number ? item.item_number : '')">
              <span>{{ item.model_name }}</span>
              <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.item_number }}</span>
            </Option>
          </Select>
        </FormItem>
        <FormItem class="marginLeft40">
          <span>批号：</span>
          <i-input placeholder="请输入" class="iviewIptWidth250" v-model="queryFrom.batch_number"></i-input>
        </FormItem>
        <br />
        <FormItem class="po-create-number" :label-width="10">
          <span class="pageBtn finger btnSure" @click="query">查询</span>
          <span class="pageBtn finger btnSure marginLeft20" @click="setupStatus = true">设置</span>
          <span class="pageBtn finger btnSure marginLeft20" @click="upload">导出</span>
        </FormItem>
      </Form>
    </div>
    <div class="tabDiv" ref="tabDiv">
      <Spin fix v-if="tabloading"></Spin>
      <Table :rowClass="rowClassName" :option_page="option_page" :productList="listColumns" :productData="listData" :chaxunFlag="chaxunFlag" :total="total" :pages="queryFrom" @change-page="changePage" :isLoad="isLoad" totalText="条记录"> </Table>
    </div>
    <!-- 动态表头弹窗 -->
    <table-setup v-if="setupStatus" :pageList="titleList" :option_page="option_page" @cancleBtn="setupStatus = false" @sureBrn="sureSetup"></table-setup>
  </div>
</template>

<script>
import Table from '@/components/table'
import TableSetup from '@/components/tableSetup'
export default {
  name: '',
  components: {
    Table,
    TableSetup,
  },
  computed: {},
  data() {
    return {
      chaxunFlag: true,
      tabloading: false,
      specification_Arr: [],
      setupStatus: false,
      option_page: '33',
      // 限制日期
      limitDate: {
        disabledDate(date) {
          return date && date.valueOf() > new Date()
        },
      },
      productList: [],
      modelList: [],
      isLoad: false,
      total: 0,
      //   totalNum: '',
      //   totalMoney: '',
      listColumns: [],
      listData: [],
      storeList: [],
      queryFrom: {
        product_code: '',
        from_warehouse_id: '',
        to_warehouse_id: '',
        product_name: '',
        specification_name: [],
        page: 1,
        rows: 10,
        batch_number: '',
        create_time_start: '',
        create_time_end: '',
      },
      options: {},
      tableHeight: 0,
    }
  },
  mounted() {
    this.queryOption() // 获取动态表头
    this.queryProductList() // 全部产品名称
    this.queryStoreList() // 仓库列表
    // this.query()
    this.querySKU('') // 全部规格型号
  },
  methods: {
    // 反冲成功的行标红
    rowClassName(row, index) {
      if (row.transfer_state == 2) {
        return 'redRow'
      }
      return ''
    },
    sureSetup(val) {
      this.queryOption()
    },
    // 获取表格表头
    queryOption() {
      this.$http
        .get(this.$api.systemOptionItem, { option_page: this.option_page })
        .then(res => {
          this.titleList = res.data.enable
          localStorage.setItem('adminTtitleList', JSON.stringify(res.data.list))
          for (let i = 0; i < this.titleList.length; i++) {
            if (this.titleList[i].show) {
              this.titleList[i].key = this.titleList[i].data_col
              this.titleList[i].width = this.titleList[i].column_width
              this.titleList[i].align = 'center'
              this.titleList[i].resizable = true
            }
          }
          this.listColumns = []
          this.listColumns.unshift({
            title: '序号',
            type: 'index',
            align: 'center',
            width: 60,
          })
          this.listColumns = [...this.listColumns, ...this.titleList]
          this.listColumns = JSON.parse(JSON.stringify(this.listColumns))
        })
        .then(() => {
          // this.queryList()
        })
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        this.queryFrom.create_time_start = e
        // 清空截止日期
        if (e > this.queryFrom.create_time_end) {
          this.queryFrom.create_time_end = ''
        }
        this.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.queryFrom.create_time_start) - 86400000
          },
        }
      } else {
        this.queryFrom.create_time_end = e
      }
    },
    // 仓库改变
    changeWare(e) {
      this.queryFrom.product_name = ''
      this.productList = []
      this.modelList = []
      if (!e) return
      this.queryProductList()
    },
    // 查询仓库产品列表
    queryProductList() {
      this.$http.get(this.$api.warehouseManagementProduct, { id: this.queryFrom.warehouse_id }, false).then(res => {
        this.productList = res.data
      })
    },
    // 获取产品id
    getProductId(e) {
      if (!e) return ''
      let id = ''
      this.productList.forEach(item => {
        if (item.product_name == e) {
          id = item.product_id
        }
      })
      return id
    },
    // 产品改变
    productChange(e) {
      if (!e) {
        this.querySKU('')
        return
      }
      this.queryFrom.specification_name = []
      this.modelList = []
      let productId = this.getProductId(e)
      this.querySKU(productId)
    },
    // 查询SKU
    querySKU(e) {
      this.$http.get(this.$api.inventoryCodeLookup, { product_id: e, warehouse_id: this.queryFrom.warehouse_id }, false).then(res => {
        this.modelList = res.data
      })
    },
    // 导出
    upload() {
      if (this.listData.length == 0) {
        this.$Message.warning('暂无数据')
        return
      }
      this.queryFrom.specification_name = this.queryFrom.specification_name ? this.queryFrom.specification_name.join(',') + '' : null
      let obj = {
        from_warehouse_id: this.queryFrom.from_warehouse_id,
        to_warehouse_id: this.queryFrom.to_warehouse_id,
        product_name: this.queryFrom.product_name,
        specification_name: this.queryFrom.specification_name,
        batch_number: this.queryFrom.batch_number,
        create_time_start: this.queryFrom.create_time_start,
        create_time_end: this.queryFrom.create_time_end,
        product_code: this.queryFrom.product_code,
      }
      this.$http.downFile(this.$api.TransferLineExport, obj, true).then(res => {
        this.$Message.success('导出成功')
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '调拨记录.xlsx'
        aLink.click()
      })
    },
    // 点击查询按钮
    query() {
      this.queryFrom.page = 1
      this.queryList()
    },
    // 查询列表数据
    queryList() {
      this.chaxunFlag = false
      this.tabloading = true
      let arr = this.specification_Arr ? this.specification_Arr.join(',') + '' : null
      let obj = {
        product_code: this.queryFrom.product_code,
        page: this.queryFrom.page,
        rows: this.queryFrom.rows,
        from_warehouse_id: this.queryFrom.from_warehouse_id,
        to_warehouse_id: this.queryFrom.to_warehouse_id,
        create_time_start: this.queryFrom.create_time_start,
        create_time_end: this.queryFrom.create_time_end,
        batch_number: this.queryFrom.batch_number,
        product_name: this.queryFrom.product_name,
        specification_name: arr,
      }
      this.$http.get(this.$api.TransferLineRecord, obj, false).then(res => {
        this.tabloading = false
        this.isLoad = false
        this.listData = res.data.transfer_line
        this.$nextTick(() => {
          this.tableHeight = this.$refs.tabDiv.offsetHeight - 50 + ''
        })
        this.total = res.data.total
        if (this.listData.length == 0) {
          this.$Message.warning('暂无数据')
          return
        } else {
          // 日期转换
          let keysList = Object.keys(this.listData[0])
          this.listData.forEach(item => {
            keysList.forEach(key => {
              if (item[key] && typeof item[key] === 'number' && (item[key] + '').length == 10) {
                if (key == 'valid_period' || key == 'production_date') {
                  item[key] = this.$moment.unix(item[key]).format('YYYY-MM-DD HH:mm:ss')
                  item[key] = this.$utils.Timetransformation(item[key]).replace(/-/gi, '')
                } else {
                  item[key] = this.$moment.unix(item[key]).format('YYYY-MM-DD')
                }
              }
            })
          })
        }
        // this.totalMoney = res.total_amount
        // this.totalNum = res.total_number
      })
    },
    // 查询仓库列表
    queryStoreList() {
      this.isLoad = true
      let data = {
        warehouse_name: '',
        page: 1,
        rows: 1000,
      }
      this.$http.get(this.$api.permissionWarehouseList, data, true).then(res => {
        this.storeList = res.data
      })
    },
    // 分页改变
    changePage(e) {
      this.queryFrom.page = e
      this.queryList()
    },
  },
}
</script>

<style scoped lang="less">
.homes {
  display: flex;
  height: 100%;
  flex-direction: column;
  .autoDiv {
    width: 100%;
    height: auto;
  }
  // 表格
  .tabDiv {
    flex: 1;
    // height: 100%;
    overflow-x: auto;
    // padding-bottom: 50px;
    // box-sizing: border-box;
  }
}

.pagePadding {
  .marginLeft20 {
    // width: 100px;
  }
  .summary > span:first-child {
    margin-right: 30px;
  }
}
.margintLeft16 {
  margin-left: 16px;
}
.noDataImg {
  position: absolute;
  top: 63%;
  left: 50%;
  transform: translateX(-54%) translateY(-50%);
  font-size: 22px;
  color: #ccc;
}
</style>
